<template>
  <section class="reservieren">
    <h1 class="h1">
      Reservieren
    </h1>

    <!-- Wrap is used to center inline-grid. -->
    <div class="rsv-stories-wrap">
      <div class="rsv-stories">
        <div class="rsv-story--1">
          <RsvStory
            :menu-route="{name: 'schmeckenGourmetRestaurant'}"
            :rsv-page-link="rsvLinkOpentable"
            img-src="/img/essraum2.jpg"
            :img-want-ratio="imgWantRatio"
            img-alt="Gourmet Restaurant"
          >
            <template #header>
              Gourmet Restaurant
            </template>

            <template #descr>
              <p>In den unverwechselbaren Räumlichkeiten des ersten Stuttgarter Bahnhofs verzaubert Sie Sternekoch Alexander Dinter am Abend von Mittwoch-Sonntag mit seiner Weltküche und dem Menu Passion.</p>
              <p>Sie wählen in unserem Sternerestaurant im 1.OG am Abend aus einem Menu von bis zu 7 Gängen.</p>
              <p>Reservieren Sie zum Lunch oder zum Dinner.</p>
            </template>
          </RsvStory>
        </div>

        <div class="rsv-story--2">
          <RsvStory
            :menu-route="{name: 'schmeckenBarRestaurant'}"
            :rsv-page-link="rsvLinkFormitable"
            img-src="/img/wohnraum6.jpg"
            :img-want-ratio="imgWantRatio"
            img-alt="Bar Restaurant"
          >
            <template #header>
              Bar Restaurant
            </template>

            <template #descr>
              <p>Auf zwei Etagen bietet das 5 eine einzigartige Mischung aus exzellenter Gourmetküche, stilechtem Restaurant, gemütlicher Lounge und schicker Bar.</p>
              <p>Auch in unserem 5 Bar Restaurant im Erdgeschoss verwöhnen wir Sie kulinarisch von Herzen.</p>
              <p>Ein Feinschmeckermenü aus bis zu 5 Gängen bringt Sie am Abend zum Schwärmen.</p>
              <p>Reservieren Sie zum Sonntags Frühstück, Lunch oder Ihrem Dinner am Abend.</p>
            </template>
          </RsvStory>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import LinkDataExt from "@/models/LinkDataExt.js"
import RsvStory from "@/components/RsvStory.vue"

export default {
  components: {
    RsvStory
  },

  data() {
    return {
      // Might require minor tweaks to get <ImgCut>s to have same height.
      imgWantRatio: 3 / 1.95,
      rsvLinkOpentable: new LinkDataExt({
        txt: "Im Gourmet Restaurant reservieren",
        href: "https://opentable.5.fo"
      }),
      rsvLinkFormitable: new LinkDataExt({
        txt: "Im Bar Restaurant reservieren",
        href: "https://formitable.5.fo"
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@use "app";
@use "txt";
@use "vars";

.reservieren {
  margin-top: app.$margin-to-nav;
  margin-bottom: txt.$blockmargin;
}

.h1 {
  @include txt.sitehead;
  @include txt.bodypadding-h;
}

.rsv-stories-wrap {
  margin-top: 4em;
  @media (max-width: vars.$break-mobile) {
    margin-top: 2em;
  }

  padding: 0 vars.$body-padding-h;
  display: flex;
  justify-content: center;
}

.rsv-stories {
  @media (min-width: vars.$max-content-w-1) {
    max-width: 75%;
  }
  display: grid;
  grid-template:
    "rsv-story--1 rsv-story--2" auto /
    1fr 1fr;
  grid-gap: 6em;

  @media (max-width: vars.$break-mobile) {
    width: 100%;
    grid-template:
      "rsv-story--1" auto
      "rsv-story--2" auto /
      1fr;
    grid-gap: 6em;
  }
}

.rsv-story--1 {
  grid-area: rsv-story--1;
}

.rsv-story--2 {
  grid-area: rsv-story--2;
}
</style>
