<template>
  <section>
    <h2 class="header">
      <slot name="header" />
    </h2>

    <ImgCut
      class="img"
      :alt="imgAlt"
      :src="imgSrc"
      :want-ratio="imgWantRatio"
    />

    <div class="menu-wrap">
      <router-link :to="menuRoute">
        Zum Menu
      </router-link>
    </div>

    <div
      v-if="$slots.descr"
      class="descr"
    >
      <!-- <p> children are styled -->
      <slot name="descr" />
    </div>

    <div class="rsv-btn-wrap">
      <BtnLinkExt
        class="rsv-btn"
        :link="rsvPageLink"
      />
    </div>
  </section>
</template>

<script>
import BtnLinkExt from "@/components/BtnLinkExt.vue"
import ImgCut from "@/components/ImgCut.vue"
import LinkDataExt from "@/models/LinkDataExt.js"

export default {
  components: {
    BtnLinkExt,
    ImgCut
  },

  props: {
    menuRoute: {
      type: Object,
      required: true
    },
    rsvPageLink: {
      type: LinkDataExt,
      required: true
    },
    imgSrc: {
      type: String,
      required: true
    },
    imgWantRatio: {
      type: Number,
      default: undefined
    },
    imgAlt: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@use "img";
@use "vars";

.header {
  font-size: 1.5em;
  text-align: center;
  color: vars.$gold;

  @media (max-width: vars.$break-mobile) {
    font-size: 1.25em;
  }
}

.img {
  margin-top: 2em;
  border: 4px solid img.$gap__color;

  @media (max-width: vars.$break-mobile) {
    margin-left: -(vars.$body-padding-h);
    margin-right: -(vars.$body-padding-h);
    border-left: 0;
    border-right: 0;
  }
}

.menu-wrap {
  margin-top: 2em;
  text-align: center;
}

.descr {
  margin-top: 2em;

  p:not(:first-of-type) {
    margin-top: 0.5em;
  }

  @media (max-width: vars.$break-mobile) {
    text-align: center;
  }
}

.rsv-btn-wrap {
  margin-top: 2em;
}

.rsv-btn {
  display: block;
  text-align: center;
}
</style>
